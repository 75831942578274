export const Connection = {
  google: "google-oauth2",
  github: "github",
  gitlab: "gitlab",
  email: "auth0",
}

export const loginUrl = ({ connection }) => {
  const loginUrl = new URL(process.env.SOURCERY_DASHBOARD_URL + "/login")
  const searchParams =
    typeof window !== "undefined"
      ? new URLSearchParams(window.location.search)
      : new URLSearchParams()
  if (connection) {
    searchParams.set("connection", connection)
  }
  loginUrl.search = searchParams.toString()
  return loginUrl.toString()
}
