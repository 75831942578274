import React from "react"
import { Link } from "gatsby"
import { MailIcon, MarkGithubIcon } from "@primer/octicons-react"
import google from "images/google-colorful.svg"
import SEO from "components/seo"
import { Connection } from "services/auth"
import { SignInAnimation } from "components/animations"
import { useLocation } from "@reach/router"
import PropTypes from "prop-types"
import { OutboundLink } from "gatsby-plugin-google-gtag"
import { loginUrl } from "services/auth"
import gitlab from "images/gitlab-logo.svg"

export function LoginMenu() {
  return (
    <div className="flex flex-col items-start justify-start h-full w-full md:w-[448px] bg-white p-8 tracking-tight">
      <p className="text-3xl mb-7">Get started</p>
      <div className="flex flex-col w-full gap-5">
        <h3 className="text-black-300 text-sm">Reviews everywhere</h3>
        <OutboundLink
          className="bg-gray-100 grid grid-cols-3 items-center h-[3rem] w-full px-5 rounded-sm hover:bg-black-100"
          href={loginUrl({ connection: Connection.github })}
        >
          <MarkGithubIcon verticalAlign="middle" size={24} />
          <span className="ml-4 font-bold">GitHub</span>
        </OutboundLink>
        <OutboundLink
          className="bg-gray-100 grid grid-cols-3 items-center h-[3rem] w-full px-5 rounded-sm hover:bg-black-100"
          href={loginUrl({ connection: Connection.gitlab })}
        >
          <img src={gitlab} width="48px" style={{ marginLeft: "-12px" }} />
          <span className="ml-4 font-bold">GitLab</span>
        </OutboundLink>
        <h3 className="text-black-300 text-sm mt-5">IDE only functionality</h3>
        <OutboundLink
          className="bg-gray-100 grid grid-cols-3  items-center h-[3rem] w-full px-5 rounded-sm hover:bg-black-100"
          href={loginUrl({ connection: Connection.google })}
        >
          <img className="w-6 h-6 filter" src={google} />
          <span className="ml-4 font-bold">Google</span>
        </OutboundLink>
        <OutboundLink
          className="bg-gray-100 grid grid-cols-3  items-center h-[3rem] w-full px-5 rounded-sm hover:bg-black-100"
          href={loginUrl({})}
        >
          <MailIcon verticalAlign="middle" size={24} />
          <span className="ml-4 font-bold">Email</span>
        </OutboundLink>
      </div>
      <p className="text-sm leading-5 text-black-300 mt-7">
        By signing up you agree to our{" "}
        <Link to="/terms/" className="text-orange-200">
          Terms of Service
        </Link>{" "}
        and{" "}
        <Link to="/privacy/" className="text-orange-200">
          Privacy Policy
        </Link>
        .
      </p>
    </div>
  )
}
LoginMenu.propTypes = {
  isSignup: PropTypes.bool.isRequired,
}

export default function SignInPage() {
  const location = useLocation()
  const isSignup = location.pathname.startsWith("/signup/")
  return (
    <>
      <SEO
        title="Sourcery Log In"
        description="Sign in to your Sourcery account"
        keywords={[`sourcery`]}
      />
      <div className="relative flex items-center justify-center w-full min-h-screen bg-black-500">
        <div className="flex flex-wrap lg:flex-nowrap gap-0 justify-center items-start lg:justify-center lg:items-center max-w-screen-2xl mx-auto px-6 py-[6rem] z-10">
          <LoginMenu isSignup={isSignup} />
        </div>
        <SignInAnimation className="absolute right-0 z-0" />
      </div>
    </>
  )
}
